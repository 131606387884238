import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faPhone, faShoppingCart, faBook, faUserTie, faClipboardList } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-[#7F0741] shadow p-4 flex justify-between items-center sticky top-0 z-10">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-10 mr-4" />
        <div className="hidden md:flex flex-col font-bold text-white">
          <p>SCHOOLRITE</p>
          <p>STATIONERY</p>
        </div>
      </div>
      <div className="hidden md:flex space-x-4">
        <Link to="/" className="text-white hover:text-gray-300">Home</Link>
        <Link to="/about" className="text-white hover:text-gray-300">About Us</Link>
        <Link to="/contact" className="text-white hover:text-gray-300">Contact</Link>
      </div>
      <div className="relative">
        <Link to="/cart" className="text-white font-bold flex items-center">
          CART <FontAwesomeIcon icon={faShoppingCart} className="ml-2" />
        </Link>
      </div>
      <div className="md:hidden flex items-center">
        <button onClick={toggleMenu} className="text-white">
          &#9776;
        </button>
      </div>
      {isOpen && (
        <div className="md:hidden fixed top-16 left-0 w-1/2 h-[calc(100vh-4rem)] bg-[#7F0741] shadow-lg z-20 overflow-y-auto">
          <button onClick={closeMenu} className="text-white absolute top-0 right-0 p-4">
            &times;
          </button>
          <Link to="/" className="px-4 py-4 text-white hover:bg-gray-700 border-t border-white flex items-center" onClick={closeMenu}>
            <FontAwesomeIcon icon={faHome} className="mr-2" />
            Home
          </Link>
          <Link to="/about" className="px-4 py-4 text-white hover:bg-gray-700 border-t border-white flex items-center" onClick={closeMenu}>
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            About Us
          </Link>
          <Link to="/contact" className="px-4 py-4 text-white hover:bg-gray-700 border-t border-white flex items-center" onClick={closeMenu}>
            <FontAwesomeIcon icon={faPhone} className="mr-2" />
            Contact
          </Link>
          <Link to="/school-stationery" className="px-4 py-4 text-white hover:bg-gray-700 border-t border-white flex items-center" onClick={closeMenu}>
            <FontAwesomeIcon icon={faBook} className="mr-2" />
            School Stationery
          </Link>
          <Link to="/office-stationery" className="px-4 py-4 text-white hover:bg-gray-700 border-t border-white flex items-center" onClick={closeMenu}>
            <FontAwesomeIcon icon={faClipboardList} className="mr-2" />
            Office Stationery
          </Link>
          <Link to="/textbooks" className="px-4 py-4 text-white hover:bg-gray-700 border-t border-white flex items-center" onClick={closeMenu}>
            <FontAwesomeIcon icon={faBook} className="mr-2" />
            Textbooks
          </Link>
          <Link to="/school-uniforms" className="px-4 py-4 text-white hover:bg-gray-700 border-t border-white flex items-center" onClick={closeMenu}>
            <FontAwesomeIcon icon={faUserTie} className="mr-2" />
            School Uniforms
          </Link>
          <Link to="/services" className="px-4 py-4 text-white hover:bg-gray-700 border-t border-white flex items-center" onClick={closeMenu}>
            <FontAwesomeIcon icon={faClipboardList} className="mr-2" />
            Services
          </Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
